import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/pgegenfurtner/Projekte/interness/atacama/atacama-packages/packages/web-core/src/templates/mdx/default.js";
import Services from '../components/Services';
import CallToAction from '@interness/web-core/src/components/modules/CallToAction/CallToAction';
import HeaderImage from '@interness/web-core/src/components/media/HeaderImage/HeaderImage';
import HeaderImg from '@interness/web-core/src/components/media/HeaderImg/HeaderImg';
import Wrapper from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <HeaderImage mdxType="HeaderImage">
    <HeaderImg id='116-leistungen-2023' mdxType="HeaderImg" />
    </HeaderImage>
    <hr></hr>
    <Wrapper mdxType="Wrapper">
      <h1>{`Leistungen`}</h1>
      <hr></hr>
      <Services type='list' mdxType="Services" />
      <div style={{
        textAlign: 'center'
      }}>
  <CallToAction mdxType="CallToAction" />
      </div>
      <hr></hr>
    </Wrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
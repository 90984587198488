import React   from 'react';
import styled  from '@emotion/styled';
import { css } from '@emotion/react';

import Link      from '@interness/web-core/src/components/elements/Link/Link';
import Separator from '@interness/web-core/src/components/structure/Separator/Separator';

import { services } from '../data/content';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  > div {
    width: ${props => props.type === 'grid' ? '28%' : '100%'};
    ${props => props.type === 'grid' ? css`
      @media (max-width: 960px) {
        width: 48%;
      }
      @media (max-width: 640px) {
        width: 100%;
      }
    ` : null};
    margin-bottom: 30px;

    h3 {
      display: inline;
    }

    .int-icon {
      display: inline;
      margin-right: 30px;

      svg {
        fill: ${props => props.theme.brand_color};
        width: 20px;
        height: 20px;
      }

      border-radius: 50%;
    }

    p {
      opacity: 0.75;
    }
  }
`;

const Services = ({ type }) => (
  <Container type={type}>
    {services.map((service, i) => {
      if (type === 'list') {
        if (!service.text) {
          return null;
        }
      }
      return (
        <div key={service.heading} id={service.heading}>
          <div className="int-icon">
            <service.icon/>
          </div>
          <h3>{service.heading}</h3>
          {type === 'grid' && <p>{service.excerpt}</p>}
          {type === 'list' && <div dangerouslySetInnerHTML={{ __html: service.text }}/>}
          {type === 'grid' && <Link to={service.link}>Mehr erfahren</Link>}
          {type === 'list' && i + 1 !== services.length && <Separator/>}
        </div>
      );
    })}
  </Container>
);

export default Services;